// assets
import {
    IconDashboard,
    IconBrandChrome,
    IconHelp,
    IconUsers,
    IconUser,
    IconRoute,
    IconLayoutKanban,
    IconReport,
    IconSettings,
    IconBed,
    IconFileInvoice,
    IconCertificate
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconBrandChrome,
    IconHelp,
    IconUsers,
    IconUser,
    IconRoute,
    IconLayoutKanban,
    IconReport,
    IconSettings,
    IconBed,
    IconFileInvoice,
    IconCertificate
};

// ==============================|| CUSTOMER MENU ITEMS ||============================== //

const doctorMenuItems = [
    // {
    //     id: 'dashboard',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'default',
    //             title: 'Dashboard',
    //             type: 'item',
    //             url: '/dashboard',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    {
        id: 'main-pages',
        type: 'group',
        children: [
            {
                id: 'patients',
                title: 'Patients',
                type: 'item',
                url: '/patients',
                icon: icons.IconUsers,
                breadcrumbs: false
            },
            {
                id: 'appointments',
                title: 'Appointments',
                type: 'collapse',
                icon: icons.IconUsers,
                breadcrumbs: false,
                children: [
                    {
                        id: 'my-appointments',
                        title: 'My Appointments',
                        type: 'item',
                        url: '/my-appointments',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'ipd',
                title: 'IPD',
                type: 'collapse',
                icon: icons.IconBed,
                breadcrumbs: false,
                children: [
                    {
                        id: 'ipd-overview',
                        title: 'IPD Overview',
                        type: 'item',
                        url: '/ipd-overview',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'billing',
                title: 'Billing',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'ipd-billing',
                        title: 'IPD Bills',
                        type: 'item',
                        url: '/billing',
                        breadcrumbs: false
                    },
                    {
                        id: 'gstr1',
                        title: 'GST Report',
                        type: 'item',
                        url: '/gst-report',
                        breadcrumbs: false
                    },
                    {
                        id: 'opdReceipts',
                        title: 'OPD Receipts',
                        type: 'item',
                        url: '/opd-receipts',
                        breadcrumbs: false
                    },
                    {
                        id: 'discharge-cards',
                        title: 'Discharge Cards',
                        type: 'item',
                        url: '/discharge-cards',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'certificates',
                title: 'Certificates',
                type: 'item',
                url: '/certificates',
                icon: icons.IconCertificate,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'main-pages',
        type: 'group',
        children: [
            {
                id: 'setting-pages',
                title: 'Settings',
                type: 'collapse',
                icon: icons.IconSettings,
                breadcrumbs: false,
                children: [
                    {
                        id: 'bill-items',
                        title: 'Bill Items',
                        type: 'item',
                        url: '/bill-items',

                        breadcrumbs: false
                    },
                    {
                        id: 'drug-items',
                        title: 'Prescription Drug Items',
                        type: 'item',
                        url: '/drug-items',

                        breadcrumbs: false
                    },
                    {
                        id: 'users',
                        title: 'Users',
                        type: 'item',
                        url: '/users',

                        breadcrumbs: false
                    }
                ]
            }
        ]
    },
    {
        id: 'main-settings',
        type: 'group',
        children: [
            {
                id: 'report-pages',
                title: 'Reports',
                type: 'collapse',
                icon: icons.IconSettings,
                breadcrumbs: false,
                children: [
                    {
                        id: 'ipd-items',
                        title: 'IPD Billing',
                        type: 'item',
                        url: '/ipd-reports',

                        breadcrumbs: false
                    },
                    {
                        id: 'opd-items',
                        title: 'OPD Billing',
                        type: 'item',
                        url: '/opd-reports',

                        breadcrumbs: false
                    }
                ]
            }
        ]
    }
];

export default doctorMenuItems;
