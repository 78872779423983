import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentItemInCustomerList: '',
    customerFilterData: {
        searchText: '',
        categoryId: 0,
        subcategoryId: 0,
        addressLine: '',
        talukaId: 0,
        areaId: 0,
        statusId: -1,
        noOfBeds: 0,
        districtId: 0,
        stateId: 0,
        pincode: '',
        billingTypeId: 0,
        gstTypeId: 0,
        pageNo: 1,
        applyFilter: false
    },
    routes: ''
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setCurretnItemInCustomerList: (state, action) => {
            state.currentItemInCustomerList = action.payload;
        },
        setCustomerFilterData: (state, action) => {
            state.customerFilterData = action.payload;
        },
        setRoutes: (state, action) => {
            state.routes = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setCurretnItemInCustomerList, setCustomerFilterData, setRoutes } = generalSlice.actions;

export default generalSlice.reducer;
