// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUsers
};

// ==============================|| Medical MENU ITEMS ||============================== //

const medicalMenuItems = [
    {
        id: 'main-pages',
        type: 'group',
        children: [
            {
                id: 'patients',
                title: 'Patients',
                type: 'item',
                icon: icons.IconUsers,
                url: '/patients',
                breadcrumbs: false
            }
        ]
    }
];

export default medicalMenuItems;
