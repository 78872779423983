import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import loginImage from 'assets/images/login-image.png';
// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const { profileId, role, roleDisplay } = useSelector((state) => state.userReducer);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, marginTop: 0.5, alignItems: 'center' }}>
                    <LogoSection />
                    <Typography variant="h3" sx={{ marginLeft: 2 }}>
                        {profileId === 2 && role === 0 ? 'Cosmoverse Sangli' : 'Swastik Hospital'}
                    </Typography>
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', margin: 'auto' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search
            <SearchSection />*/}
            <Box sx={{ flexGrow: 1 }} />
            <img src={loginImage} alt="Logo" width="40" />
            <Stack alignItems="center" sx={{ marginLeft: 2 }}>
                <Typography variant="h2" color="secondary">
                    HMS Portal
                </Typography>
                <Typography variant="body1">({roleDisplay})</Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            {/* this is to add space in between*/}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
