import { useRoutes } from 'react-router-dom';
import { useReducer, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import config from 'config';

// login option 3 routing
const Login = Loadable(lazy(() => import('views/login')));
const ForgotPassword = Loadable(lazy(() => import('views/login/forgot-password')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// patient page routing
const PatientList = Loadable(lazy(() => import('views/patients/patient-list')));
const PatientForm = Loadable(lazy(() => import('views/patients/patient-form')));
const PatientDetails = Loadable(lazy(() => import('views/patients/patient-details')));
const BillList = Loadable(lazy(() => import('views/billing/bill-list')));
const BillForm = Loadable(lazy(() => import('views/billing/bill-form')));
const DischargeCards = Loadable(lazy(() => import('views/discharge-cards/discharge-card-list')));
const DischargeCardForm = Loadable(lazy(() => import('views/discharge-cards/discharge-card-form')));
const OpdBill = Loadable(lazy(() => import('views/billing/opd-bill')));

// appointment page routing
const Appointments = Loadable(lazy(() => import('views/appointments/today-appointment')));
const AppointmentForm = Loadable(lazy(() => import('views/appointments/appointment-form')));
const AllAppointments = Loadable(lazy(() => import('views/appointments/all-appointments')));
const AppointmentDetails = Loadable(lazy(() => import('views/appointments/appointment-details')));
const IPDOverview = Loadable(lazy(() => import('views/ipd/ipd-overview')));
const MyAppointments = Loadable(lazy(() => import('views/appointments/my-appointments')));
const PrescriptionList = Loadable(lazy(() => import('views/prescription-list')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const BillItems = Loadable(lazy(() => import('views/settings/bill-items')));
const Users = Loadable(lazy(() => import('views/settings/users')));
const MLCForm = Loadable(lazy(() => import('views/appointments/mlc-form')));
const DrugItems = Loadable(lazy(() => import('views/settings/drug-items')));
const GSTR1Report = Loadable(lazy(() => import('views/billing/gst-report')));

//Certificate page routing

const Certificates = Loadable(lazy(() => import('views/certificates/index')));

//Report page routing

const OPDBillReport = Loadable(lazy(() => import('views/reports/OPDBillingReports/index')));
const IPDBillReport = Loadable(lazy(() => import('views/reports/IPDBillingReports/index')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { userId, userName, role, displayRole } = useSelector((state) => state.userReducer);

    const AuthenticationRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            }
        ]
    };

    const MainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: userId > 0 ? <PatientList /> : <Navigate to="/login" />
            },
            {
                path: '/sample-page',
                element: userId > 0 ? <SamplePage /> : <Navigate to="/login" />
            },
            {
                path: '/dashboard',
                element: userId > 0 ? <DashboardDefault /> : <Navigate to="/login" />
            },
            {
                path: '/patients',
                element: userId > 0 ? <PatientList /> : <Navigate to="/login" />
            },
            {
                path: '/patient-form',
                element: userId > 0 ? <PatientForm /> : <Navigate to="/login" />
            },
            {
                path: '/patient-details',
                element: userId > 0 ? <PatientDetails /> : <Navigate to="/login" />
            },
            {
                path: '/billing',
                element: userId > 0 ? <BillList /> : <Navigate to="/login" />
            },
            {
                path: '/gst-report',
                element: userId > 0 ? <GSTR1Report /> : <Navigate to="/login" />
            },
            {
                path: '/prescription-list',
                element: userId > 0 ? <PrescriptionList /> : <Navigate to="/login" />
            },
            {
                path: '/bill-form',
                element: userId > 0 ? <BillForm /> : <Navigate to="/login" />
            },
            {
                path: '/opd-receipts',
                element: userId > 0 ? <OpdBill /> : <Navigate to="/login" />
            },
            {
                path: '/discharge-cards',
                element: userId > 0 ? <DischargeCards /> : <Navigate to="/login" />
            },
            {
                path: '/discharge-card-form',
                element: userId > 0 ? <DischargeCardForm /> : <Navigate to="/login" />
            },
            {
                path: '/todays-appointments',
                element: userId > 0 ? <Appointments /> : <Navigate to="/login" />
            },
            {
                path: '/appointment-form',
                element: userId > 0 ? <AppointmentForm /> : <Navigate to="/login" />
            },
            {
                path: '/all-appointments',
                element: userId > 0 ? <AllAppointments /> : <Navigate to="/login" />
            },
            {
                path: '/my-appointments',
                element: userId > 0 ? <MyAppointments /> : <Navigate to="/login" />
            },
            {
                path: '/appointment-details',
                element: userId > 0 ? <AppointmentDetails /> : <Navigate to="/login" />
            },

            { path: '/ipd-overview', element: userId > 0 ? <IPDOverview /> : <Navigate to="/login" /> },
            {
                path: '/bill-items',
                element: userId > 0 ? <BillItems /> : <Navigate to="/login" />
            },
            {
                path: '/drug-items',
                element: userId > 0 ? <DrugItems /> : <Navigate to="/login" />
            },
            {
                path: '/users',
                element: userId > 0 ? <Users /> : <Navigate to="/login" />
            },
            {
                path: '/ipd-overview',
                element: userId > 0 ? <IPDOverview /> : <Navigate to="/login" />
            },
            {
                path: '/mlc-form',
                element: userId > 0 ? <MLCForm /> : <Navigate to="/login" />
            },
            {
                path: '/certificates',
                element: userId > 0 ? <Certificates /> : <Navigate to="/login" />
            },
            {
                path: '/opd-reports',
                element: userId > 0 ? <OPDBillReport /> : <Navigate to="/login" />
            },
            {
                path: '/ipd-reports',
                element: userId > 0 ? <IPDBillReport /> : <Navigate to="/login" />
            }
        ]
    };

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
